/*
  1. Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  3. Allow percentage-based heights in the application
*/
html, body {
  height: 100%;
  top:0 !important
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-family: 'Open Sans', sans-serif;
}
/*
  6. Improve media defaults
*/
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
/*
  9. Create a root stacking context
*/
#root, #__next {
  isolation: isolate;
}
/*
 10. remove google translate bar
*/
.goog-te-gadget-simple a {
  text-decoration: none !important;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
#goog-gt-tt {
  display: none !important;
}                         

/*font family Open Sans import*/
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700;800;900&display=swap');
body, html, #root {
  height: 100%;
  font-family: 'Open Sans', sans-serif !important;
}

#toast-container > .report-info {            
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
  background-color: #778737;
  opacity: 1;
}

/* History trail timeline chart styles */
  #timelinechart .timeSliceBars, #timelinechart .alertPoints {
    cursor: pointer;
  }
  .timelineTooltip {
    position: fixed;
    opacity: 0;
    background-color: black;
    color: #fff;
    border: 1px solid black;
    border-radius: 5px;
    text-transform: capitalize;
    padding: 5px 10px;
    font-size: 0.9rem;
    z-index: 999999;
    white-space: nowrap;
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.25);
  }

  /*routing & Dipsatch Route Detail timeline chart*/
  .assigned{
    background:var(--asc-cultured);
    border: 1px solid var(--asc-coolgray);
    color:var(--asc-dimgray);
  }

  .completed{
    background:var(--asc-selectedgreen);
    border: 1px solid var(--asc-moss);
    color:var(--asc-darkolive);
  }
  
  .late{
    background:var(--asc-vermilion);
    border: 1px solid var(--asc-vermilion);
    color:white;
  }

  .arrived{
    background:var(--asc-lime);
    border: 1px solid var(--asc-lime);
    color:black;
  }
  .missed{
    background:var(--asc-vermilion);
    border: 1px solid var(--asc-vermilion);
    color:white;
  }

  .completed.late{
    background:var(--asc-selectedgreen);
    border: 1px solid var(--asc-vermilion);
    color:var(--asc-darkolive);
  }